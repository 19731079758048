.dashboard {
    width: 97%;
    border-collapse: collapse;
    margin: 0.5% auto 2%;
    border: 0 solid black;
}

.dashboard-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    padding: 10px;
}

.dashboard-grid-item-container {
    border: 1.6px solid var(--bs-xploregroup-green);
    border-radius: 15px;
    padding: 10px;
    background-color: white;
}

[data-bs-theme='dark'] .dashboard-grid-item-container {
    background-color: var(--bs-xploregroup-dark-blue-hover);
}

.userContainer {
    display: grid;
    grid-template-columns: 0.8fr 1.8fr 0.4fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 5px 5px;
    grid-auto-flow: row;
    grid-template-areas:
        'csNumber initials status status status status'
        'kWh kWh chargingTime chargingTime occupationTime occupationTime';
    grid-area: userContainer;
}

.adminContainerUp,
.adminContainerDown {
    height: 3.8em;
}

.adminContainerUp {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    gap: 5px 5px;
    grid-auto-flow: row;
    grid-template-areas: 'transactionButton';
    grid-area: AdminContainer;
    border-top: 1px solid var(--bs-xploregroup-very-dark-grayish-blue);
    padding-top: 5px;
}

[data-bs-theme='dark'] .adminContainerUp {
    border-top: 1px solid var(--bs-xploregroup-dark-gray);
}

.adminContainerDown {
    display: grid;
    grid-template-columns: 1.6fr 0.4fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    gap: 5px 5px;
    grid-auto-flow: column;
    grid-template-areas:
        'unknownFcText unknownFcToggle'
        'stationDownText stationDownToggle'
        'stationReminderText stationReminderToggle'
        'sendEmailDashboardText sendEmailDashboard'
        'blockedChargingStationToggleLabel blockedChargingStationToggle';
    grid-area: AdminContainer;
    border-top: 1px solid var(--bs-xploregroup-very-dark-grayish-blue);
    padding-top: 5px;
    height: fit-content;
}

[data-bs-theme='dark'] .adminContainerDown {
    border-top: 1px solid var(--bs-xploregroup-dark-gray);
}

.blockedChargingStationToggleLabel,
.blockedChargingStationToggle {
    padding-top: 5px;
    border-top: 1px solid var(--bs-xploregroup-very-dark-grayish-blue);
}

[data-bs-theme='dark'] .blockedChargingStationToggleLabel,
[data-bs-theme='dark'] .blockedChargingStationToggle {
    border-top: 1px solid var(--bs-xploregroup-dark-gray);
}

.userContainer > div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 0 solid black;
    border-radius: 5px;
}

.adminContainer > div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 0 solid black;
    border-radius: 5px;
}

.csNumber {
    grid-area: csNumber;
}

.initials {
    grid-area: initials;
}

.status {
    grid-area: status;
}

.status-admin-popover {
    padding-bottom: 1em;
}

.status-admin-popover p {
    margin: 0;
}

.status-admin-popover .status-admin-popover-key {
    font-weight: bold;
}

.status-admin-popover .status-admin-popover-value {
    font-style: italic;
}

div.kWh {
    grid-area: kWh;
    justify-content: end;
    padding-right: 1vw;
}

div.kWh svg {
    margin-right: 0.2vw;
}

.chargingTime {
    grid-area: chargingTime;
}

.occupationTime {
    grid-area: occupationTime;
}

.dashboard-island-notification-toggle-text,
.dashboard-island-charging-station-blocked-toggle-text{
    margin: 0;
    padding: 0;
    font-size: 85%;
}

.stationDownText {
    grid-area: stationDownText;
}

.stationDownToggle {
    grid-area: stationDownToggle;
}

.stationReminderText {
    grid-area: stationReminderText;
}

.stationReminderToggle {
    grid-area: stationReminderToggle;
}

.unknownFcText {
    grid-area: unknownFcText;
}

.unknownFcToggle {
    grid-area: unknownFcToggle;
}

.blockedChargingStationToggleLabel {
    grid-area: blockedChargingStationToggleLabel;
}

.blockedChargingStationToggle {
    grid-area: blockedChargingStationToggle;
}

.sendEmailDashboardText {
    margin: auto;
}

.sendEmailDashboard {
    grid-area: sendEmailDashboard;
    margin: auto;
    width: fit-content;
    cursor: pointer;
}

.stationDownText,
.stationDownToggle,
.stationReminderText,
.stationReminderToggle,
.unknownFcText,
.unknownFcToggle,
.blockedChargingStationToggleLabel,
.blockedChargingStationToggle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stationDownText,
.stationReminderText {
    text-align: center;
}

.unkownFuelCardText {
    grid-area: unkownFuelCardText;
}

.unkownFuelCardToggle {
    grid-area: unkownFuelCardToggle;
}

.transactionButton {
    grid-area: transactionButton;
    display: flex;
    justify-content: center;
    align-items: center;
}

.transactionButton button,
.transactionButton button:hover,
.transactionButton button:disabled {
    width: 100%;
    color: black;
}

[data-bs-theme='dark'] .transactionButton button {
    color: white;
}

.center-grid-text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.circle-container {
    position: relative;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1.2px solid var(--bs-xploregroup-very-dark-grayish-blue);
    padding-top: 10%;
}

[data-bs-theme='dark'] .circle-container {
    border: 1.2px solid var(--bs-xploregroup-dark-gray);
}

.circle-container-down {
    background-color: #f8cecc;
}

[data-bs-theme='dark'] .circle-container-down {
    background-color: rgba(255, 93, 104, 0.2);
    border: 1.2px solid rgba(255, 93, 104, 0.2);
}

.circle-container h4 {
    padding-top: 10%;
}

.crossed::before,
.crossed::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 2px;
    background-color: red;
}

.crossed::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.crossed::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.NoPaddingOrMargin {
    padding: 0;
    margin: 0;
}

.biggerFontSize {
    font-size: 105%;
}

.switchButtons {
    width: 35%;
}

.startTransactionModalBody {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
}

.startTransactionButton {
    width: 40%;
}

.blockedFuelCardInitials {
    text-decoration: Line-Through;
    color: red;
}

@media (max-width: 1250px) {
    .dashboard-grid-container {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .switchButtons {
        width: 60%;
    }
}

@media (max-width: 850px) {
    .dashboard-grid-container {
        grid-template-columns: 1fr 1fr;
    }

    .switchButtons {
        width: 80%;
    }
}

@media (max-width: 600px) {
    .dashboard-grid-container {
        grid-template-columns: 1fr;
    }

    .dashboard-grid-item-container {
        padding: 8px;
    }

    .switchButtons {
        width: 100%;
    }

    .startTransactionModalBody {
        width: 100%;
    }

    .startTransactionButton {
        width: 60%;
    }

    div.kWh {
        padding-right: 5vw;
    }
}

@media (max-width: 350px) {
    .dashboard-grid-container {
        grid-template-columns: 1fr;
    }

    .dashboard-grid-item-container {
        padding: 5px;
    }

    .dashboard p {
        font-size: 85%;
    }

    .biggerFontSize {
        font-size: 85%;
    }

    .switchButtons {
        width: 100%;
    }
}
